.page-banner {
	position: relative;

	.banner-text {
		position: absolute;
		width: 100%;
		text-align: center;
		z-index: 2;
		padding-top: 65px;

		.logo img {
			width: 356px;
			height: 315px;
		}

		h1 {
			margin-top: 70px;
			color: #fff;
			font-size: 117px;
			letter-spacing: 35px;
			text-shadow: 3px 3px 10px rgba(0,0,0,0.38);
		}
	}

}
