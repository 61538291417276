/*
==========================================================================
   #Page
========================================================================== */
html {
	font-family: 'Proxima', sans-serif;
	font-size: ($base-font-size / 16px) * 1em;
	color: $primary;
	line-height: $base-line-height;
	letter-spacing: 3px;

	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	@include breakpoint(not-desktop){
		font-size: 14px;
		line-height: 18px;
	}
}

body {
	margin: 0;
	padding: 0;
	overflow: hidden;

	&.legal,
	&.hideContent {
		overflow: initial;
	}

	@include breakpoint(tablet){
		p {
			font-size: 14px;
			line-height: 18px;
		}
	}
}


body * {
	box-sizing: border-box;
}

p {
	margin: 20px 0;

	&:first-child {
		margin-top: 0;
	}
}

ol {
	font-weight: 600;

	 p,
	 ol {
		font-weight: normal;
	}
}

li {
	margin-bottom: 10px;
}
