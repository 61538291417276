/*
==========================================================================
   #Wrapper
========================================================================== */
.wrapper {
	display: block;
	max-width: 1200px;
	padding: 0 10px;
	margin: auto;
	overflow: hidden;

	@include breakpoint(not-desktop){
		padding: 0 20px;
	}
}
