.product-item-single {
	text-align: center;
}

.product-items {
	margin-top: 40px;
    display: flex;
    justify-content: space-around;
	align-items: flex-end;
	
	&.product-items__twocolumn {
		flex-wrap: wrap;
		margin-bottom: 30px;

		.product-item {
			width: 60%;

			&:nth-child(2n) {
				width: 40%;
			}
		}
	}
}

.product-item {
	text-align: center;

	@include breakpoint(mobile) {
		width: 50%;
		word-wrap: break-word;
	}

	img {
		margin-bottom: 20px;
	}
}

.product-text p {
	font-size: 13px;
}

body.products  {
	.page-content {
		max-width: 1000px;
	}

	.item-page p {
		font-size: 20px;
		line-height: 28px;

		@include breakpoint(mobile) {
			font-size: 14px;
    		line-height: 18px;
    	}
	}

	.product-text p {
		font-size: 13px;
		line-height: 18px;

		span.aox-logo {
			height: 9px;
    		width: 35px;
		}
	}
}

@include breakpoint(mobile) {
	.table-wrapper {
		overflow-x:scroll; 
		padding: 20px 0;
	}
}


table.storing {
	width: 820px;
	max-width: 100%;
	color: #000000;
	text-align: left;
	font-size: 12px;
	line-height: 16px;
	border-collapse: collapse;

	th {
		font-weight: 500;
		font-size: 16px;
		text-transform: uppercase;
		border-bottom: 1px solid #000;
		padding-bottom: 5px;
		padding-top: 20px;
	}

	td {
		max-width: 370px;
		padding-right: 10px;

		p {
			margin: 0;
			line-height: 16px;
		}

		p.small {
			line-height: 12px;
			font-size: 8px;
		}
	}

	small {
		font-size: 8px;
		line-height: 10px;
	}

}