/*
==========================================================================
   #Heading
========================================================================== */
%heading {
	font-weight: 600;
	line-height: 1.1em;
	margin-bottom: 20px;
	border: 0 none;
	padding: 0;
	margin-top: 0;
	color: $primary;
	font-family: 'StratumNo1Heavy', 'serif';

	@include breakpoint(mobile) {
		margin-bottom: 10px;
	}
}

%h1,
h1 {
	@extend %heading;
	font-size: 42px;
	@include breakpoint(not-desktop) {
		font-size:32px;
		line-height:1.125em; /* 36px */
	}
}

%h2,
h2 {
	@extend %heading;
	color: $primary;
	font-weight:bold;
	font-size: 30px;
	line-height: 1.125em; /* 36px */
	@include breakpoint(tablet) {
		font-size:24px;
		line-height:1.167em; /* 28px */
	}

	@include breakpoint(mobile){
		font-size:18px;
		line-height:22px; /* 28px */
	}
}

%h3,
h3 {
	@extend %heading;
	color: $primary;
	font-size: 26px;
	line-height: 1.154em; /* 30px */
	@include breakpoint(not-desktop) {
		font-size:20px;
	}
}

%h4,
h4 {
	@extend %heading;
	color: $color-h4;
	font-size: 22px;
	line-height:1.182em; /* 26px */
	@include breakpoint(not-desktop) {
		font-size:17px;
	}
}

%h5,
h5 {
	@extend %heading;
	font-size: 20px;
	line-height:1.2em; /* 24px */
}

%h6,
h6 {
	@extend %heading;
	font-size: 18px;
	line-height:1.222em; /* 22px */
}

h1, h2, h3, h4, h5, h6 {
	a
	, a:focus
	, a:visited {
		color: inherit;
		text-decoration: none;
		font-family: inherit;
		font-size: inherit;
	}
	a:hover {
		color: inherit;
		text-decoration: underline;
	}
}
