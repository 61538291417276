.page-head {
	position: fixed;
	width: 100%;
	height: 100vh;
	z-index: 3;
	transition: all 0.3s ease-in-out;
	overflow: hidden;

	.home.hideContent & {
		height: 0;
		opacity: 0;
	}

	.legal & {
		display: none;
	}
}


.page-head-internal{
	display: none;
	padding: 20px 0;

	img {
		margin: auto;
		display: block;
	}
}

.internal .page-head-internal {
	display: block;
}

.home .page-head {
	position: fixed;
	z-index: 3;
	width: 100%;
	height: 100vh;
}

.page-content {

	margin: auto;
	min-height: 400px;
	text-align: center;
}


.item-page {
	text-align: left;
	.short {
		max-width: 780px;
		margin: auto;
	}
}