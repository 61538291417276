/*
==========================================================================
   #Links
========================================================================== */
a {
	text-decoration: underline;
	color: inherit;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	cursor: pointer;

	&:focus,
	&:hover {
		color: $color-orange;
		text-decoration: underline;
	}

	&:focus {
		outline: inherit dotted 1px;
		outline: currentColor dotted 1px;
		outline: -webkit-focus-ring-color auto 5px;
	}
}
