.ultraselect {
    .select {
        border: 1px solid currentColor;
        padding: 7px 15px;
        display: block;
        position: relative;
        &:hover {
            cursor: pointer;
        }
        &.focus {
            border: 1px inset $color-textinput-focus-shadow;
        }
        .arrow {
            width: 35px;
            border-left: 1px solid currentColor;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            &:before {
                font-size: 10px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: auto;
                @extend %icon, %icon-arrow-down;
                transition: transform $arrow-transition-time;
            }
        }
        &.active {
            .arrow {
                &:before {
                    transform: translate(-50%, -50%) rotate(180deg);
                    transition: transform $arrow-transition-time;
                }
            }
        }
    }

    .options {
        display: block;
        position: relative;
        z-index: 25;
        overflow-y: auto;
        overflow-x: hidden;
        border: 1px solid currentColor;
        border-top: none;
        background: #fff;
        &:focus {
            outline: 0;
            box-shadow: 0 0 0 2px $color-textinput-focus-shadow;
        }
        .option {
            padding: 5px;
            display: block;
            &:hover {
                cursor: pointer;
            }
            label {
                display: block;
            }
        }

    }
}

