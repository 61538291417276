.icon {
	text-indent: 1000px;
	overflow: hidden;
	display: inline-block;
	width: 40px;
    height: 40px;
    background-size: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;

    @include breakpoint(not-desktop){
        width: 30px;
        height: 30px;
    }
}

.icon-facebook {
	background-image: url("../images/facebook.png");
	&:hover {
    	background-image: url("../images/facebook-hover.png");
    }
}

.icon-instagram {
	background-image: url("../images/instagram.png");
    &:hover {
    	background-image: url("../images/instagram-hover.png");
    }
}