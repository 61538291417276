/*
==========================================================================
   #Page header
========================================================================== */
.page-head {
	background: #e71132 url('../images/pattern-red.png');
	color: #fff;
	position: relative;
	height: 100vh;


	&:before {
		content: " ";
		width: 100%;
		height: 100%;
		background: transparent url('../images/overlay-black.png');
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		background-size: cover; 
	}
}

.page-head-navigation {

	.wrapper {
		display: flex;
    justify-content: center;
    align-items: center;
		height: 100vh;
		
		@include breakpoint(mobile) {
			display: block;
		}
	}

	h2 {
		text-align: center;
		color: #eaa818;
		font-size: 45px;
		line-height: 60px;

		@include breakpoint(mobile) {
			font-size: 35px;
			line-height: 40px;
		}
	}

	.header-logo{
		width: 40%;
		float: left;

		@include breakpoint(mobile) {
			width: 100%;
			padding-top: 30px;

			img {
				width: 40%;
				margin: auto;
				display: block;
				margin-top: 20px;
			}
		}
	}
	.header-form{
		float: left;
		width: 60%;
		padding-left: 70px;
		max-width: 670px;
		text-align: center;
		font-family: "StratumNo1Heavy", serif;
		letter-spacing: 2px;
		font-weight: normal;

		@include breakpoint(mobile) {
			width: 100%;
			padding: 0;
		}

		.form-fields {
			overflow: hidden;
			text-align: center;
			margin-bottom: 40px;
		}

		.field-group {
			display: inline-block;
			margin: 0 10px;
			position: relative;
		}

		label {
			color: #fff;
			font-size: 42px;
		}
	}

	.btn-enter {
		background: transparent;
		border: 2px solid #fff;
		font-size: 42px;
		padding: 27px 40px;
		font-family: "StratumNo1Heavy", serif;
		letter-spacing: 2px;
		font-weight: normal;
		-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
		cursor: pointer;
		position: relative;
		z-index: 2;

		@include breakpoint(mobile) {
			font-size: 35px;
			line-height: 40px;

			padding: 15px 30px;
		}
	}

	
}


