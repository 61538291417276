.moduletable.section {
    &.bg-red {
        background: #e71132 url('../images/pattern-red.png');
        color: #fff;
        position: relative;

        h2 {
            color: #eaa818;
            font-size: 48px;
            line-height: 50px;
            letter-spacing: 3px;
        }

        &.bg-overlay {
            &:before {
                content: " ";
                width: 100%;
                height: 100%;
                background: transparent url('../images/overlay-black.png');
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                background-size: cover; 
            }
        }
    }

    &.beers {
        padding: 30px 0 60px;
    }

    &.no-p-padding 
    {
        p {
            margin: 0;
        }

    }
    &.food-section {
       
        img {
            width: 100%;
        }
    }

    &.bg-black {
        background: #000 url('../images/bg-black.jpg') no-repeat center center;
        padding: 75px 0;

        color: #fff;
        position: relative;

        h2 {
            color: #eaa818;
            font-size: 48px;
            line-height: 50px;
            letter-spacing: 3px;
        }
    }
}

.numbered {
    list-style: none;
    position: relative;
}

.numbered > li {
    padding-left: 30px;
  font-weight: normal;
}
.numbered > li > span {
    position: absolute;
    left: 30px;
}

.privacy {
    padding-bottom: 50px;
}