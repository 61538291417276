/*
==========================================================================
   #Buttons
========================================================================== */

$transition-time: 0.2s;
%btn,
.btn {
    display: inline-block;
    max-width: 100%;
    padding: 20px 55px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    text-transform:uppercase;
    cursor: pointer;
    border: 1px solid #4d4f53;
    transition: color $transition-time, background $transition-time;

    @include breakpoint(mobile) {
        width: 100%;
        @include font-size(14px, 14px);
        padding-top: 12px;
        padding-bottom: 12px;
        margin-bottom: 10px;
    }

    &:hover {
        text-decoration: none;
        transition: color $transition-time, background $transition-time;
        color: #fff;
        background-color: #4d4f53;
    }
    &:active {
        box-shadow: none !important;
    }
    &:focus{
        text-decoration:none;
    }
}

.btn--large {
    @extend %btn;
    @include font-size(22px, 26px);
    padding: 20px 30px;

    @include breakpoint(tablet) {
        @include font-size(16px, 22px);
        padding: 11px 28px;
    }

    @include breakpoint(mobile) {
        @include font-size(14px, 18px);
        padding-top: 14px;
        padding-bottom: 14px;
    }
}
