/*
==========================================================================
   #Form elements
========================================================================== */

/**
 * Placeholder
 */
::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.6;
}

:-moz-placeholder { /* Firefox 18- */
    color: inherit;
    opacity: 0.6;
}

::-moz-placeholder { /* Firefox 19+ */
    color: inherit;
    opacity: 0.6;
}

:-ms-input-placeholder {
    color: inherit;
    opacity: 0.6;
}

/**
 * Label
 */
label {
    color: $color-label-text;
}

/**
 * Text input
 */
%input,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="text"],
input[type="search"],
textarea,
select {
    display: inline-block;
    box-sizing: border-box;
    color: $color-textinput-text;
    border: 2px solid $color-inputs-border;
    -webkkit-border-radius: 2px;
    border-radius: 2px;
    padding: 5px 18px;
    height: 48px;
    max-width: 100%;
    background: $color-textinput-bg;
    -webkit-transition: background 0.2s, color 0.2s;
    transition: background 0.2s, color 0.2s;
    -webkit-appearance: none; /* Remove ios text input inner shadow */

    &:focus,
    .no-touch &:hover {
        background: $color-textinput-hover-bg;
    }

    &:focus {
        outline: 0;
        border: 1.5px inset $color-textinput-focus-shadow;
    }
}

textarea {
    padding: 10px 15px;
    height: auto;
    resize: vertical;
}

/**
 * Select
 */
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    padding-right: 48px + 20px;
    background: transparent;
    border: 0;
    box-sizing:border-box;
    &:focus{
        box-shadow: 0 0 0 1.5px $color-textinput-focus-shadow inset;
        border:0;
        background: transparent;
    }
    .no-touch &:hover {
        background: transparent;
    }
}

// Hide default select arrow in IE
select::-ms-expand {
    display: none;
}

.select-wrapper {
    position: relative;
    display: inline-block;
    .arrow {
        width: 48px;
        height: 48px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        background-color:$color-black;
        &:before {
            font-size: 10px;
            color:$color-white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: auto;
            @extend %icon, %icon-arrow-down;
            transition: transform 0.1s;
        }
    }
    &:hover .arrow{
        background-color:$primary;
    }
    &.active {
        .arrow {
            &:before {
                transform: translate(-50%, -50%) rotate(180deg);
                transition: transform 0.1s;
            }
        }
    }
    &:before{
        content: '';
        z-index:-1;
        box-sizing:border-box;
        width:100%;
        height:100%;
        position: absolute;
        left:0;
        top:0;
        border:2px solid $color-inputs-border;
    }
}

/**
 * Checkbox
 */
label + input[type=radio]:checked + label,
input[type=radio]:not(checked) + label {
    position: relative;
    margin-bottom: 10px;
    box-sizing: border-box;

    @include breakpoint(mobile) {
        margin-bottom: 22px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

input[type=radio]:not(:checked),
input[type=radio]:checked {
    position: absolute;
    opacity: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}

input[type=radio]:not(:checked) + label,
input[type=radio]:checked + label {
    position: relative;
    display: inline-block;
    padding-left: 68px;
    cursor: pointer;
    line-height: 40px;

    @include breakpoint(mobile) {
        line-height: 44px;
    }
}

input[type=radio]:not(:checked) + label:after, input[type=radio]:checked + label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 42px;
    height: 40px;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid #dedede;
    display: block;
}

input[type=radio]:checked + label:before {
    //content: '';
    position: absolute;
    left: 12px;
    top: 0;
    z-index: 2;
    color: #fff;
    font-size: 13px;
    transition: all .2s;
    @extend %icon, %icon-tick;
}



/**
 * Buttons
 */
%button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    @extend %btn;
}
