/*
==========================================================================
   #Page footer
========================================================================== */
.page-footer {
    background: #e71132 url('../images/pattern-red.png');
    color: #fff;
    position: relative;
    padding: 235px 0 65px;
    
        
    @include breakpoint(not-desktop) {
        padding: 100px 0 65px;
    }

    h2 {
        color: #eaa818;
        font-size: 170px;
        letter-spacing: 3px;
        font-family: "BillyOhio", serif;
        font-weight: normal;
        line-height: 170px;

        @include breakpoint(not-desktop) {
            font-size: 100px;
            line-height: 100px;
        }
    }

    .footer-logo {
        float: left;

        @include breakpoint(tablet) {
            width: 30%;
        }

        @include breakpoint(mobile) {
            width: 80%;
            margin: auto;
            float: none;
        }
    }

    .footer-text {
        float: left;
        margin-left: 90px;
        padding-top: 120px;

        @include breakpoint(mobile) {
            padding-top: 20px;
            width: 50%;
            margin-left: 10%;
        }

        @include breakpoint(mobile) {
            margin: auto;
            width: 100%;
            text-align: center;
        }
    }

    .footer-email {
        text-align: center;
        
    }

    .footer-copy {
        margin-top: 220px;
        text-align: center;
        p {
            margin: 0;
        }

        @include breakpoint(not-desktop) {
            margin-top: 50px;
        }
    }

}

