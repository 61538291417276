/*
==========================================================================
   #Fonts
========================================================================== */
$font-stack:
(group: PTSerif, id: Regular, font: ('PT Serif', 'Arial', 'serif'), weight: 400, style: normal),
(group: PTSerif, id: Bold, font: ('PT Serif', 'Arial', 'serif'), weight: 700, style: normal);


/** Proxima Nova **/



@font-face {
    font-family: 'Proxima';
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Black-webfont.eot');
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Black-webfont.woff2') format('woff2'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Black-webfont.woff') format('woff'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Black-webfont.ttf') format('truetype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Black-webfont.svg#proxima_nova_altblack') format('svg');
    font-weight: 800;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima';
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Bold-webfont.eot');
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Bold-webfont.woff2') format('woff2'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Bold-webfont.woff') format('woff'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Bold-webfont.ttf') format('truetype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Bold-webfont.svg#proxima_nova_altbold') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima';
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Regular-webfont.eot');
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Regular-webfont.woff2') format('woff2'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Regular-webfont.woff') format('woff'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Regular-webfont.ttf') format('truetype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Alt Regular-webfont.svg#proxima_nova_altregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on April 4, 2015 */



@font-face {
    font-family: 'Proxima';
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova ScOsf Thin-webfont.eot');
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova ScOsf Thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova ScOsf Thin-webfont.woff2') format('woff2'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova ScOsf Thin-webfont.woff') format('woff'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova ScOsf Thin-webfont.ttf') format('truetype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova ScOsf Thin-webfont.svg#proxima_nova_scosfthin') format('svg');
    font-weight: 200;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima';
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova Semibold-webfont.eot');
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Semibold-webfont.woff2') format('woff2'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Semibold-webfont.woff') format('woff'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Semibold-webfont.ttf') format('truetype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Semibold-webfont.svg#proxima_nova_ltsemibold') format('svg');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima';
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova Thin-webfont.eot');
    src: url('../fonts/proxima/Mark Simonson - Proxima Nova Thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Thin-webfont.woff2') format('woff2'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Thin-webfont.woff') format('woff'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Thin-webfont.ttf') format('truetype'),
         url('../fonts/proxima/Mark Simonson - Proxima Nova Thin-webfont.svg#proxima_nova_ltthin') format('svg');
    font-weight: 100;
    font-style: normal;

}

@font-face {
    font-family: 'StratumNo1Heavy';
    src: url('../fonts/stratum/StratumNo1Heavy.eot');
    src: url('../fonts/stratum/StratumNo1Heavy.eot') format('embedded-opentype'),
         url('../fonts/stratum/StratumNo1Heavy.woff2') format('woff2'),
         url('../fonts/stratum/StratumNo1Heavy.woff') format('woff'),
         url('../fonts/stratum/StratumNo1Heavy.ttf') format('truetype'),
         url('../fonts/stratum/StratumNo1Heavy.svg#StratumNo1Heavy') format('svg');
}

@font-face {
    font-family: 'BillyOhio';
    src: url('../fonts/billyohio/BillyOhio.eot');
    src: url('../fonts/billyohio/BillyOhio.eot') format('embedded-opentype'),
         url('../fonts/billyohio/BillyOhio.woff2') format('woff2'),
         url('../fonts/billyohio/BillyOhio.woff') format('woff'),
         url('../fonts/billyohio/BillyOhio.ttf') format('truetype'),
         url('../fonts/billyohio/BillyOhio.svg#BillyOhio') format('svg');
}




@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/slick/slick.eot');
    src: url('../fonts/slick/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick/slick.woff') format('woff'), url('../fonts/slick/slick.ttf') format('truetype'), url('../fonts/slick/slick.svg#slick') format('svg');
}

