/*
==========================================================================
   #Messages
========================================================================== */
/* System Messages */
#system-message {
	position: relative;
	width:100%;
	clear:both;
	padding: 0;
	margin: 0;
}
#system-message h4 {
	display:none;
}
#system-message .close {
	position: absolute;
	top: 15px;
	right: 15px;
	display: block;
	line-height: 10px;
	cursor: pointer;
	text-decoration: none;
	color: inherit;
}
#system-message .alert {
	position: relative;
	padding: 15px 25px 15px 60px;
	margin-bottom: 20px;
}
#system-message .alert p {
	margin:0;
}

/* System Standard Messages */
#system-message > .alert-message {
	color: #4b88c7;
	background: #d3e4f5 url(../images/site/notice-info.png) 15px center no-repeat;
	border: 3px solid #4b88c7;
}
/* System Error Messages */
#system-message > .alert-error {
	color: #f30045;
	background: #ffd7e2 url(../images/site/notice-alert.png) 15px center no-repeat;
	border: 3px solid #f30045;
}
/* System Notice Messages */
#system-message > .alert-notice, #system-message > .alert-warning {
	color: #BB8B00;
	background: #FFFAED url(../images/site/notice-notice.png) 15px center no-repeat;
	border: 3px solid #ecb925;
}
