ul.menu {
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: auto;
	float: left;
	margin-top: 40px;

	li {
		float: left;
		margin-left: 35px;

		@include breakpoint(tablet){
			margin-left: 15px;
		}

		a {
			color: $primary;
			text-decoration: none;
			font-size: 14px;
			text-transform: uppercase;
			display: block;
			padding: 13px 0;
			position: relative;
			cursor: pointer;

			@include breakpoint(not-desktop){
				font-size: 14px;
			}

		
			&:hover,
			&.active{
				font-weight: 600;
			}
		}

		&.active a {
			font-weight: 600;
		}
	}
}